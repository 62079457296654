<!--Vista para editar información que se muestra en el micrositio del evento-->
<template>
  <v-container fluid>
    <div>
      <v-card color="px-0 px-md-5 py-7 rounded-xl elevation-0">
        <v-row>
          <v-col cols="12" md="8" class="py-0">
            <h2 class="font-weight-bold">
              Vamos a construir tu micrositio de evento
            </h2>
          </v-col>
          <v-col cols="12" md="4" class="py-0 d-flex flex-row justify-end">
            <!--<span class="mt-3 mr-3">Publicar evento</span>
            <v-switch
              class="mt-2"
              color="accent"
              hide-details
            ></v-switch>-->
            <!--
              $_publishEvent()
            -->
            <v-tooltip v-if="!event.published" top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  @click="openNotificationDialog"
                  color="secondary"
                  large
                  rounded
                  v-bind="attrs"
                  v-on="on"
                  :disabled="
                    !user.permissions.Event || !user.permissions.Event.update
                  "
                >
                  <span class="font-weight-bold">Publicar evento</span>
                </v-btn>
              </template>
              <span
                >Da clic para publicar tu evento y permitir que tus asistentes
                se registren</span
              >
            </v-tooltip>
            <v-tooltip v-else top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  @click="$_hideEvent"
                  color="grey lighten-3"
                  large
                  rounded
                  v-bind="attrs"
                  v-on="on"
                  :disabled="
                    !user.permissions.Event || !user.permissions.Event.update
                  "
                >
                  <span class="font-weight-bold">Ocultar evento</span>
                </v-btn>
              </template>

              <span>Da clic para bloquear el registro a tu evento</span>
            </v-tooltip>

            <v-dialog v-model="confirmationDialog" max-width="600">
              <v-card>
                <v-card-title class="title font-weight-bold mb-1">
                  ¿Desea enviar una notificación por email a los miembros de la
                  organización?
                </v-card-title>
                <v-card-text>
                  <v-row>
                    <v-col cols="12">
                      <span></span>
                      <v-radio-group v-model="selectedOption" row>
                        <v-radio
                          label="Sí, deseo enviar notificación vía email a mis miembros"
                          value="yes"
                          @change="onOptionChange"
                        ></v-radio>
                        <v-radio
                          label="No, no enviar notificación"
                          value="no"
                        ></v-radio>
                      </v-radio-group>
                    </v-col>
                    <v-col v-if="selectedOption === 'yes'" cols="12">
                      <v-combobox
                        v-model="selectedSubgroups"
                        label="¿A qué grupo de miembros deseas enviar notificaciones?"
                        :items="subgroups"
                        background-color="#f9fafc"
                        persistent-hint
                        hide-details
                        filled
                        :multiple="true"
                      />
                    </v-col>
                  </v-row>
                </v-card-text>
                <v-card-actions class="d-flex justify-end">
                  <v-btn
                    color="grey lighten-2"
                    @click="confirmationDialog = false"
                  >
                    Cancelar
                  </v-btn>
                  <v-btn color="accent" @click="confirmPublish">
                    Publicar
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-col>
          <v-col cols="12">
            <v-row>
              <v-col cols="12" class="pb-0">
                <p class="title font-weight-bold mb-1">
                  Banner y diseño del evento
                </p>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" md="6">
            <v-row>
              <v-col cols="12" class="py-0">
                <p>
                  Carga un banner de 1500x500 píxeles
                  <v-tooltip top max-width="350px">
                    <template v-slot:activator="{ on }">
                      <v-icon color="primary" dark v-on="on"
                        >mdi-help-circle</v-icon
                      >
                    </template>
                    <span
                      >Esta imagen se mostrará en la parte superior del
                      micrositio. Para tener una visualización adecuada se
                      recomienda que la imagen sea de 1500x500 pixeles.</span
                    >
                  </v-tooltip>
                </p>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="pt-0">
                <v-img
                  v-if="event.banner && event.banner != ''"
                  class="align-content-center rounded-xl"
                  width="auto"
                  height="350"
                  :src="event.banner"
                >
                  <div
                    class="d-flex justify-center"
                    style="height: 100%; align-items: center"
                  >
                    <v-btn
                      color="accent"
                      fab
                      large
                      @click="bannerDialog = true"
                      :disabled="
                        !user.permissions.Event ||
                        !user.permissions.Event.update
                      "
                    >
                      <i class="fas fa-plus fa-2x black--text"></i>
                    </v-btn>
                  </div>
                </v-img>
                <v-img
                  v-else
                  class="align-content-center rounded-xl"
                  width="auto"
                  height="350"
                  src="@/assets/images/dashboard/bannerPlaceholder.png"
                >
                  <div
                    class="d-flex justify-center"
                    style="height: 100%; align-items: center"
                  >
                    <v-btn
                      color="accent"
                      fab
                      large
                      @click="bannerDialog = true"
                      :disabled="
                        !user.permissions.Event ||
                        !user.permissions.Event.update
                      "
                    >
                      <i class="fas fa-plus fa-2x black--text"></i>
                    </v-btn>
                  </div>
                </v-img>
              </v-col>
              <!--<v-col cols="12" sm="10" :class="$vuetify.breakpoint.xsOnly?'pb-0':''">
                <div :class="$vuetify.breakpoint.smAndUp?'d-flex flex-row':''">
                  <span class="subtitle-2 font-weight-light align-self-center mr-2 mb-7">https://wechamber.mx/eventos/</span>
                  <v-text-field
                    v-model="event.micrositeConfiguration.link"
                    label="Capa de link"
                    filled
                    rounded
                    @blur="_updateEvent()"
                  ></v-text-field>
                </div>
              </v-col>
              <v-col cols="12" sm="2" :class="$vuetify.breakpoint.xsOnly?'d-flex justify-center pt-0':''">
                <v-btn color="accent" fab>
                  <i class="fas fa-link fa-lg black--text"></i>
                </v-btn>
              </v-col>-->
            </v-row>
          </v-col>
          <v-col cols="12" md="6">
            <v-row>
              <v-col cols="12" class="py-0">
                <p>
                  Carga un flyer de 800xN píxeles
                  <v-tooltip top max-width="350px">
                    <template v-slot:activator="{ on }">
                      <v-icon color="primary" dark v-on="on"
                        >mdi-help-circle</v-icon
                      >
                    </template>
                    <span
                      >Esta imagen se mostrará en el cuerpo del micrositio.
                      Normalmente se usa para mostrar información del evento de
                      una forma más gráfica. Para tener una visualización
                      adecuada se recomienda que la imagen sea de 800 pixeles de
                      ancho y de alto puede ser cualquier medida.</span
                    >
                  </v-tooltip>
                </p>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="8" class="pt-0">
                <v-img
                  v-if="event.flyer && event.flyer != ''"
                  class="align-content-center rounded-xl"
                  width="auto"
                  height="350"
                  :src="event.flyer.split(',')[0]"
                >
                  <div
                    class="d-flex justify-center"
                    style="height: 100%; align-items: center"
                  >
                    <v-btn
                      color="accent"
                      fab
                      large
                      @click="flyerDialog = true"
                      :disabled="
                        !user.permissions.Event ||
                        !user.permissions.Event.update
                      "
                    >
                      <i class="fas fa-plus fa-2x black--text"></i>
                    </v-btn>
                  </div>
                </v-img>
                <v-img
                  v-else
                  class="align-content-center rounded-xl"
                  width="auto"
                  height="350"
                  src="@/assets/images/dashboard/flyerPlaceholder.png"
                >
                  <div
                    class="d-flex justify-center"
                    style="height: 100%; align-items: center"
                  >
                    <v-btn
                      color="accent"
                      fab
                      large
                      @click="flyerDialog = true"
                      :disabled="
                        !user.permissions.Event ||
                        !user.permissions.Event.update
                      "
                    >
                      <i class="fas fa-plus fa-2x black--text"></i>
                    </v-btn>
                  </div>
                </v-img>
              </v-col>
              <!--
              <v-col cols="12" sm="8">
                <v-row>
                  <v-col cols="12" class="py-0">
                    <v-text-field
                      v-model="event.name"
                      label="Dale un nombre a tu evento"
                      filled
                      rounded
                      @blur="_updateEvent()"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" class="d-flex justify-center pt-0">
                    <v-btn class="black--text font-weight-bold" color="accent" x-large @click="descriptionDialog = true">
                      Descripción de evento
                    </v-btn>
                  </v-col>
                </v-row>
              </v-col>-->
              <v-col cols="12" sm="4">
                <v-row>
                  <v-col cols="12" class="py-0">
                    <v-text-field
                      v-model="event.name"
                      label="Dale un nombre a tu evento"
                      filled
                      rounded
                      :readonly="
                        !user.permissions.Event ||
                        !user.permissions.Event.update
                      "
                      @blur="_updateEvent()"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" class="py-0">
                    <v-text-field
                      v-model="event.emailContact"
                      label="Correo electrónico de contacto"
                      filled
                      rounded
                      :readonly="
                        !user.permissions.Event ||
                        !user.permissions.Event.update
                      "
                      @blur="_updateEvent()"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" class="py-0">
                    <v-text-field
                      v-model="event.youtubeVideo"
                      label="Link de video promocional"
                      filled
                      rounded
                      :readonly="
                        !user.permissions.Event ||
                        !user.permissions.Event.update
                      "
                      @blur="_updateEvent()"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <!--
                <v-row>
                  <v-col>
                    <v-card class="align-content-center" min-height="150" max-height="200" :color="event.micrositeConfiguration.primaryColor">
                      <div class="d-flex align-content-space-between flex-wrap" style="min-height: 150px; min-height: 150px; align-items: center;" min-height="150" max-height="200">
                        <div class="ma-4" style="width: 100%">
                          <p class="title font-weight-bold ma-0" :style="`color: ${event.micrositeConfiguration.secondaryColor}`">
                            Texto
                          </p>
                        </div>
                        <div class="ma-4 d-flex justify-end" style="width: 100%">
                          <v-btn :color="event.micrositeConfiguration.buttonColor" rounded :dark="event.micrositeConfiguration.textButtonColor=='white'"><span class="font-weight-bold">Botón</span></v-btn>
                        </div>
                      </div>
                    </v-card>
                  </v-col>
                </v-row>-->
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" class="pb-0">
            <p class="title font-weight-bold">Configuración de colores</p>
          </v-col>
          <v-col cols="12" class="py-0">
            <v-row>
              <v-col cols="12" sm="3" class="py-0">
                <v-text-field
                  class="mt-0"
                  label="Color primario"
                  filled
                  rounded
                  readonly
                  @click="primaryColorDialog = true"
                  :disabled="
                    !user.permissions.Event || !user.permissions.Event.update
                  "
                >
                  <template v-slot:append>
                    <v-icon
                      :color="event.micrositeConfiguration.primaryColor"
                      large
                      @click="primaryColorDialog = true"
                      :disabled="
                        !user.permissions.Event ||
                        !user.permissions.Event.update
                      "
                      >fas fa-square</v-icon
                    >
                  </template>
                  <template v-slot:append-outer>
                    <v-tooltip top max-width="350px">
                      <template v-slot:activator="{ on }">
                        <v-icon color="primary" dark v-on="on"
                          >mdi-help-circle</v-icon
                        >
                      </template>
                      <span
                        >Será el color más visible dentro de tu micrisitio,
                        puedes elegir tu color institucional o el de tu
                        preferencia.</span
                      >
                    </v-tooltip>
                  </template>
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="3" class="py-0">
                <v-text-field
                  label="Color secundario"
                  filled
                  rounded
                  readonly
                  @click="secondaryColorDialog = true"
                  :disabled="
                    !user.permissions.Event || !user.permissions.Event.update
                  "
                >
                  <template v-slot:append>
                    <v-icon
                      :color="event.micrositeConfiguration.secondaryColor"
                      large
                      @click="secondaryColorDialog = true"
                      :disabled="
                        !user.permissions.Event ||
                        !user.permissions.Event.update
                      "
                      >fas fa-square</v-icon
                    >
                  </template>
                  <template v-slot:append-outer>
                    <v-tooltip top max-width="350px">
                      <template v-slot:activator="{ on }">
                        <v-icon color="primary" dark v-on="on"
                          >mdi-help-circle</v-icon
                        >
                      </template>
                      <span
                        >Aquí puedes elegir el color del botón de adquirir
                        acceso y el color de las amenidades.</span
                      >
                    </v-tooltip>
                  </template>
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="3" class="py-0">
                <v-text-field
                  label="Color botón"
                  filled
                  rounded
                  readonly
                  @click="buttonColorDialog = true"
                  :disabled="
                    !user.permissions.Event || !user.permissions.Event.update
                  "
                >
                  <template v-slot:append>
                    <v-icon
                      :color="event.micrositeConfiguration.buttonColor"
                      large
                      @click="buttonColorDialog = true"
                      :disabled="
                        !user.permissions.Event ||
                        !user.permissions.Event.update
                      "
                      >fas fa-square</v-icon
                    >
                  </template>
                  <!--<template v-slot:append-outer>
                    <v-tooltip top max-width="350px">
                      <template v-slot:activator="{ on }">
                        <v-icon
                          color="primary"
                          dark
                          v-on="on"
                          >mdi-help-circle</v-icon
                        >
                      </template>
                      <span
                        >Lorem ipsum dolor sit amet consectetur adipisicing elit. Laudantium distinctio quia neque, voluptatibus, pariatur eum velit perspiciatis laboriosam veritatis, exercitationem reprehenderi.</span
                      >
                    </v-tooltip>
                  </template>-->
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="3" class="pt-0">
                <v-switch
                  v-model="event.micrositeConfiguration.textButtonColor"
                  class="mt-0"
                  color="black"
                  false-value="white"
                  true-value="black"
                  :label="`Texto botón ${
                    event.micrositeConfiguration.textButtonColor == 'black'
                      ? '(Negro)'
                      : '(Blanco)'
                  }`"
                  @change="_updateEvent()"
                  :disabled="
                    !user.permissions.Event || !user.permissions.Event.update
                  "
                >
                  <template v-slot:label>
                    {{
                      `Texto botón ${
                        event.micrositeConfiguration.textButtonColor == "black"
                          ? "(Negro)"
                          : "(Blanco)"
                      }`
                    }}
                    <!--<v-tooltip top max-width="350px">
                    <template v-slot:activator="{ on }">
                      <v-icon
                        color="primary"
                        class="ml-2"
                        dark
                        v-on="on"
                        >mdi-help-circle</v-icon
                      >
                    </template>
                    <span
                      >Lorem ipsum dolor sit amet consectetur adipisicing elit. Laudantium distinctio quia neque, voluptatibus, pariatur eum velit perspiciatis laboriosam veritatis, exercitationem reprehenderi.</span
                    >
                  </v-tooltip>-->
                  </template>
                </v-switch>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12">
            <v-row
              class="py-5 mx-1"
              v-bind:style="`background-color: ${
                event.micrositeConfiguration &&
                event.micrositeConfiguration.primaryColor
                  ? event.micrositeConfiguration.primaryColor
                  : '#f0f0f0'
              }; border-radius: 20px`"
            >
              <v-col cols="12" md="8" lg="9" class="pl-12">
                <p
                  class="mb-1 font-weight-medium subtitle-1"
                  :style="`color: ${event.micrositeConfiguration.secondaryColor}`"
                >
                  {{ dateEvent() }}
                </p>
                <p
                  class="mb-1 font-weight-black display-1"
                  :style="`color: ${event.micrositeConfiguration.secondaryColor}`"
                >
                  {{ event.name }}
                </p>
                <!--<p
                  class="mb-1 font-weight-medium subtitle-1"
                  :style="`color: ${event.micrositeConfiguration.secondaryColor}`"
                >
                  {{event.type}} / {{event.category}}
                </p>-->
                <p
                  class="mb-1 font-weight-medium subtitle-1"
                  :style="`color: ${event.micrositeConfiguration.secondaryColor}`"
                >
                  <v-icon :color="event.micrositeConfiguration.secondaryColor">
                    {{
                      event.eventModality == "PRESENTIAL"
                        ? "mdi-google-maps"
                        : event.eventModality == "VIRTUAL"
                        ? "mdi-earth"
                        : "mdi-merge"
                    }}
                  </v-icon>
                  {{ eventModalities[event.eventModality] }}
                </p>
              </v-col>
              <v-col cols="12" md="4" lg="3" class="d-flex align-center">
                <v-row class="d-flex justify-end">
                  <!--<v-col cols="12" class="pa-0 d-flex justify-center">
                    <p
                      class="font-weight-bold subtitle-1"
                      :style="`color: ${event.micrositeConfiguration.secondaryColor}`"
                    >
                      ¡Quedan pocos lugares!
                    </p>
                  </v-col>-->
                  <v-col cols="12" class="pa-0 d-flex justify-center">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          class="font-weight-bold"
                          :color="event.micrositeConfiguration.buttonColor"
                          rounded
                          :dark="
                            event.micrositeConfiguration.textButtonColor ==
                            'white'
                          "
                          v-bind="attrs"
                          v-on="on"
                          >Adquirir acceso
                        </v-btn>
                      </template>
                      <span>Botón ilustrativo, no tiene funcionalidad</span>
                    </v-tooltip>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" class="pb-0">
            <p class="title font-weight-bold mb-0">Descripción del evento</p>
          </v-col>
          <v-col cols="12">
            <v-row>
              <v-col cols="12" md="8">
                <v-row>
                  <v-col cols="12">
                    <tiptap-vuetify
                      v-model="event.description"
                      :extensions="extensions"
                      :max-height="$vuetify.breakpoint.smAndUp ? '450' : '250'"
                      :toolbar-attributes="{
                        color: 'primary',
                        rounded: 't-lg',
                        dark: true,
                      }"
                      :card-props="{
                        elevation: '0',
                        rounded: 'lg',
                        color: '#f0f0f0',
                      }"
                      :disabled="
                        !user.permissions.Event ||
                        !user.permissions.Event.update
                      "
                    />
                  </v-col>
                  <v-col cols="12">
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          class="font-weight-bold black--text"
                          color="secondary"
                          rounded
                          v-bind="attrs"
                          v-on="on"
                          @click="_updateEvent()"
                          :disabled="
                            !user.permissions.Event ||
                            !user.permissions.Event.update
                          "
                          >Guardar descripción</v-btn
                        >
                      </template>
                      <span>Guardar cambios de descripción del evento</span>
                    </v-tooltip>
                  </v-col>
                </v-row>
              </v-col>
              <v-col cols="12" md="4">
                <v-row>
                  <v-col cols="12" class="pb-0">
                    <v-select
                      v-model="event.eventModality"
                      :items="modalityCategories"
                      label="Modalidad"
                      filled
                      rounded
                      @change="_updateEvent()"
                      :disabled="
                        !user.permissions.Event ||
                        !user.permissions.Event.update
                      "
                    >
                      <template slot="selection" slot-scope="data">
                        <span>{{ data.item.item }}</span>
                      </template>
                      <template slot="item" slot-scope="data">
                        <span>{{ data.item.item }}</span>
                      </template>
                    </v-select>
                  </v-col>
                  <v-col
                    v-if="
                      event.eventModality == 'PRESENTIAL' ||
                      event.eventModality == 'HYBRID'
                    "
                    cols="12"
                  >
                    <div
                      class="v-input theme--light v-text-field v-text-field--filled v-text-field--is-booted v-text-field--enclosed v-text-field--rounded"
                    >
                      <div class="v-input__control d-flex">
                        <div class="v-input__slot">
                          <div class="v-input__prepend-inner">
                            <div
                              class="v-input__icon v-input__icon--prepend-inner"
                            >
                              <i
                                aria-hidden="true"
                                class="v-icon notranslate fas fa-map-marker-alt theme--light"
                              ></i>
                            </div>
                          </div>
                          <div class="v-text-field__slot">
                            <label
                              for="input-274"
                              class="v-label theme--light"
                              style="left: 0px; right: auto; position: absolute"
                            ></label>
                            <gmap-autocomplete
                              placeholder="Ubicación del recinto *"
                              required
                              style="margin-top: 15px"
                              @place_changed="saveAddress"
                              :disabled="
                                !user.permissions.Event ||
                                !user.permissions.Event.update
                              "
                              :value="place"
                            ></gmap-autocomplete>
                          </div>
                        </div>
                      </div>
                      <v-tooltip top max-width="350px">
                        <template v-slot:activator="{ on }">
                          <v-icon
                            color="primary"
                            dark
                            v-on="on"
                            class="ml-2 mt-4"
                            >mdi-help-circle</v-icon
                          >
                        </template>
                        <span
                          >Para llenar este campo correctamente es necesario
                          seleccionar alguna de las opciones que se sugieren al
                          momento de estar escribiendo la ubicación.</span
                        >
                      </v-tooltip>
                    </div>
                  </v-col>
                  <v-col
                    v-if="
                      event.virtualEventConfiguration &&
                      (event.eventModality == 'VIRTUAL' ||
                        event.eventModality == 'HYBRID')
                    "
                    cols="12"
                    class="py-0 py-sm-2"
                  >
                    <v-text-field
                      v-model="event.virtualEventConfiguration.url"
                      label="Url de Webinar"
                      filled
                      rounded
                      :disabled="
                        !user.permissions.Event ||
                        !user.permissions.Event.update
                      "
                      @blur="_updateEvent()"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" class="pb-0">
            <p class="title font-weight-bold">Redes sociales</p>
          </v-col>
          <v-col cols="12" class="py-0">
            <v-row v-if="event.socialMedia">
              <v-col cols="12" sm="3" class="py-0">
                <v-text-field
                  v-model="event.socialMedia.facebook"
                  class="mt-0"
                  label="Facebook"
                  filled
                  rounded
                  :disabled="
                    !user.permissions.Event || !user.permissions.Event.update
                  "
                  @blur="_updateEvent()"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="3" class="py-0">
                <v-text-field
                  v-model="event.socialMedia.instagram"
                  class="mt-0"
                  label="Instagram"
                  filled
                  rounded
                  :disabled="
                    !user.permissions.Event || !user.permissions.Event.update
                  "
                  @blur="_updateEvent()"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="3" class="py-0">
                <v-text-field
                  v-model="event.socialMedia.twitter"
                  class="mt-0"
                  label="Twitter"
                  filled
                  rounded
                  :disabled="
                    !user.permissions.Event || !user.permissions.Event.update
                  "
                  @blur="_updateEvent()"
                >
                </v-text-field>
              </v-col>
              <v-col cols="12" sm="3" class="pt-0">
                <v-text-field
                  v-model="event.socialMedia.linkedin"
                  class="mt-0"
                  label="LinkedIn"
                  filled
                  rounded
                  :disabled="
                    !user.permissions.Event || !user.permissions.Event.update
                  "
                  @blur="_updateEvent()"
                >
                </v-text-field>
              </v-col>
            </v-row>
          </v-col>
          <!--Patrocinadores-->
          <v-col cols="12" class="pb-0">
            <p class="title font-weight-bold text-center">Patrocinadores</p>
          </v-col>
          <v-col
            v-if="event.sponsors && event.sponsors.length > 0"
            cols="12"
            class="d-flex justify-center py-0"
          >
            <v-row justify="center">
              <v-col
                v-for="sponsor in event.sponsors"
                :key="sponsor._id"
                cols="6"
                sm="4"
                lg="3"
                xl="2"
                class="py-2 d-flex justify-center"
              >
                <v-avatar size="120" rounded="0">
                  <v-img
                    class="sponsor-img rounded-xl"
                    lazy-src="https://picsum.photos/id/11/10/6"
                    :src="sponsor.logo"
                    width="auto"
                    contain
                  ></v-img>
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        absolute
                        fab
                        top
                        right
                        color="red"
                        small
                        class="mt-6"
                        @click="openRemoveSponsorDialog(sponsor)"
                        :disabled="
                          !user.permissions.Event ||
                          !user.permissions.Event.update
                        "
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon small color="white"
                          >mdi-trash-can-outline</v-icon
                        >
                      </v-btn>
                    </template>
                    <span>Eliminar patrocinador</span>
                  </v-tooltip>
                </v-avatar>
              </v-col>
              <v-col
                cols="6"
                sm="4"
                lg="3"
                xl="2"
                class="py-2 d-flex justify-center"
              >
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="accent"
                      fab
                      x-large
                      @click="sponsorDialog = true"
                      :disabled="
                        !user.permissions.Event ||
                        !user.permissions.Event.update
                      "
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon>mdi-plus</v-icon>
                    </v-btn>
                  </template>
                  <span>Agregar patrocinador</span>
                </v-tooltip>
              </v-col>
            </v-row>
          </v-col>
          <v-col v-else cols="12" class="d-flex justify-center py-0">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  color="accent"
                  fab
                  x-large
                  @click="sponsorDialog = true"
                  :disabled="
                    !user.permissions.Event || !user.permissions.Event.update
                  "
                  v-bind="attrs"
                  v-on="on"
                >
                  <v-icon>mdi-plus</v-icon>
                </v-btn>
              </template>
              <span>Agregar patrocinador</span>
            </v-tooltip>
          </v-col>
        </v-row>
      </v-card>
    </div>
    <!--INICIO v-dialog para cargar banner-->
    <!-- <v-dialog v-model="bannerDialog" width="700">
      <v-card>
        <v-card-text :class="$vuetify.breakpoint.smAndUp ? '' : 'px-2'">
          <v-container>
            <v-row>
              <v-col cols="12" class="mt-3 d-flex justify-center">
                <v-img
                  class=""
                  src="@/assets/images/weChamber/logo-WC-main-nav-drawer.svg"
                  max-width="190"
                  height="auto"
                  contain
                ></v-img>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <p class="headline font-weight-bold text-center mb-0">
                  Banner de evento
                </p>
              </v-col>
              <v-col cols="12" class="subtitle-1 text-justify py-0">
                <p>
                  Esta imagen se mostrará en la parte superior del micrositio.
                  Para tener una visualización adecuada se recomienda que la
                  imagen sea de 1500x500 pixeles.
                </p>
              </v-col>
              <v-col cols="12">
                <file-pond
                  name="event-banner"
                  ref="pondBanner"
                  label-idle="Agrega un banner para tu evento..."
                  accepted-file-types="image/jpeg, image/png"
                />
              </v-col>
            </v-row>
            <v-row
              class="d-flex pb-2 mt-5"
              :class="
                $vuetify.breakpoint.smAndUp ? 'justify-end' : 'justify-center'
              "
            >
              <v-btn
                class="mx-2"
                color="grey lighten-2"
                rounded
                @click="bannerDialog = false"
                elevation="0"
                ><span class="font-weight-bold black--text">Cerrar</span></v-btn
              >
              <v-btn
                class="mx-2"
                color="accent"
                rounded
                elevation="0"
                @click="uploadBanner(), (bannerDialog = false)"
                ><span class="font-weight-bold black--text"
                  >Guardar</span
                ></v-btn
              >
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog> -->
    <ImageCroppingDialog
      :cropperDialog="bannerDialog"
      @submitImages="uploadBanners"
      @close="bannerDialog = false"
    >
      <template v-slot:dialogTitle> Banner de evento </template>
      <template v-slot:dialogSubtitle>
        Esta imagen se mostrará en la parte superior del micrositio. Para tener
        una visualización adecuada
        <span class="font-weight-bold"
          >se recomienda que la imagen sea de 1500x500 pixeles</span
        >.
      </template>
    </ImageCroppingDialog>
    <!--FIN v-dialog para cargar banner-->
    <!--INICIO v-dialog para cargar flyer-->
    <ImageCroppingDialog
      :flyers="this.event.flyer"
      :cropperDialog="flyerDialog"
      :flyer="true"
      @submitImage="uploadFlyer($event)"
      @submitImages="uploadFlyers"
      @close="flyerDialog = false"
      :show-carousel-switch="true"
      @banderaImageChange="handleBanderaImageChange"
      @updateMultipleImages="handleMultipleImagesUpdate"
    >
      <template v-slot:dialogTitle> Flyer de evento </template>
      <template v-slot:dialogSubtitle>
        Esta imagen se mostrará en el cuerpo del micrositio. Normalmente se usa
        para mostrar información del evento de una forma más gráfica. Para tener
        una visualización adecuada
        <span class="font-weight-bold"
          >se recomienda que la imagen sea de 800 pixeles de ancho y de alto
          puede ser cualquier medida</span
        >
        .
      </template>
    </ImageCroppingDialog>
    <!--FIN v-dialog para cargar flyer-->
    <!--INICIO v-dialog para cargar patrocinador-->
    <v-dialog v-model="sponsorDialog" width="700">
      <v-card>
        <v-card-text :class="$vuetify.breakpoint.smAndUp ? '' : 'px-2'">
          <v-container>
            <v-row>
              <v-col cols="12" class="mt-3 d-flex justify-center">
                <v-img
                  class=""
                  src="@/assets/images/weChamber/logo-WC-main-nav-drawer.svg"
                  max-width="190"
                  height="auto"
                  contain
                ></v-img>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <p class="headline font-weight-bold text-center mb-0">
                  Patrocinadores
                </p>
              </v-col>
              <v-col cols="12" class="subtitle-1 text-justify py-0">
                <p>
                  Esta imagen se mostrará en el micrositio. Para tener una
                  visualización adecuada se recomienda que la imagen sea de
                  500x500 pixeles.
                </p>
              </v-col>
              <v-col cols="12">
                <file-pond
                  name="sponsor-image"
                  ref="sponsorImage"
                  label-idle="Agrega una imagen de patrocinador para tu evento..."
                  accepted-file-types="image/jpeg, image/png"
                />
              </v-col>
              <v-col cols="12" class="pt-0">
                <v-text-field
                  dense
                  filled
                  rounded
                  label="Link al sitio del patrocinador"
                  v-model="sponsorLink"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row
              class="d-flex pb-2 mt-5"
              :class="
                $vuetify.breakpoint.smAndUp ? 'justify-end' : 'justify-center'
              "
            >
              <v-btn
                class="mx-2"
                color="grey lighten-2"
                rounded
                @click="
                  sponsorDialog = false;
                  sponsorLink = '';
                "
                elevation="0"
                ><span class="font-weight-bold black--text">Cerrar</span></v-btn
              >
              <v-btn
                class="mx-2"
                color="accent"
                rounded
                elevation="0"
                @click="
                  uploadSponsor(), (sponsorDialog = false), (sponsorLink = '')
                "
                ><span class="font-weight-bold black--text"
                  >Guardar</span
                ></v-btn
              >
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!--FIN v-dialog para cargar patrocinador-->
    <!--INICIO v-dialog para eliminar patrocinador-->
    <v-dialog v-model="removeSponsorDialog" width="700">
      <v-card>
        <v-card-text :class="$vuetify.breakpoint.smAndUp ? '' : 'px-2'">
          <v-container>
            <v-row>
              <v-col cols="12" class="mt-3 d-flex justify-center">
                <v-img
                  class=""
                  src="@/assets/images/weChamber/logo-WC-main-nav-drawer.svg"
                  max-width="190"
                  height="auto"
                  contain
                ></v-img>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <p class="headline font-weight-bold text-center mb-0">
                  Patrocinadores
                </p>
              </v-col>
              <v-col cols="12" class="subtitle-1 py-0">
                <p>
                  ¿Está seguro que quiere eliminar la imagen del patrocinador
                  seleccionado?. La imagen no podrá ser recuperada a menos que
                  sea subida nuevamente.
                </p>
              </v-col>
            </v-row>
            <v-row
              class="d-flex pb-2 mt-5"
              :class="
                $vuetify.breakpoint.smAndUp ? 'justify-end' : 'justify-center'
              "
            >
              <v-btn
                class="mx-2"
                color="grey lighten-2"
                rounded
                @click="removeSponsorDialog = false"
                elevation="0"
                ><span class="font-weight-bold black--text">Cerrar</span></v-btn
              >
              <v-btn
                class="mx-2"
                color="red"
                rounded
                elevation="0"
                @click="removeSponsor()"
                ><span class="font-weight-bold white--text"
                  >Remover</span
                ></v-btn
              >
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!--FIN v-dialog eliminar patrocinador-->
    <!--INICIO ESTE DIALOG SE VA A QUITAR-->
    <v-dialog v-model="descriptionDialog" max-width="750">
      <v-card>
        <p class="headline font-weight-medium px-7 pt-5">
          Descripción de evento
        </p>
        <v-card-text class="d-flex justify-center pa-2">
          <tiptap-vuetify
            v-model="event.description"
            :extensions="extensions"
            :max-height="$vuetify.breakpoint.smAndUp ? '450' : '250'"
          />
        </v-card-text>
        <v-card-actions class="d-flex justify-end pb-5 pr-5">
          <v-btn
            color="grey lighten-3"
            @click="cancelUpdateEvent(), (descriptionDialog = false)"
          >
            Cancelar
          </v-btn>
          <v-btn
            color="accent"
            @click="_updateEvent(), (descriptionDialog = false)"
          >
            Guardar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--FIN ESTE DIALOG SE VA A QUITAR-->
    <!--INICIO v-dialog para seleccionar el color primario-->
    <v-dialog v-model="primaryColorDialog" max-width="400">
      <v-card>
        <p class="title font-weight-bold text-center px-7 pt-5">
          Selecciona el color primario
        </p>
        <v-card-text class="d-flex justify-center pa-2">
          <v-color-picker
            v-model="event.micrositeConfiguration.primaryColor"
            class="v-color-picker-style ma-0 ma-md-2"
            mode="hexa"
            hide-mode-switch
            show-swatches
            swatches-max-height="300px"
          ></v-color-picker>
        </v-card-text>
        <v-card-actions class="d-flex justify-end pb-5 pr-5">
          <v-btn
            class="font-weight-bold black--text"
            color="grey lighten-3"
            rounded
            elevation="0"
            @click="cancelUpdateEvent(), (primaryColorDialog = false)"
            >Cancelar</v-btn
          >
          <v-btn
            class="font-weight-bold black--text"
            color="accent"
            rounded
            elevation="0"
            @click="_updateEvent(), (primaryColorDialog = false)"
            >Guardar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--FIN v-dialog para seleccionar el color primario-->
    <!--INICIO v-dialog para seleccionar el color secundario-->
    <v-dialog v-model="secondaryColorDialog" max-width="400">
      <v-card>
        <p class="title font-weight-bold text-center px-7 pt-5">
          Selecciona el color secundario
        </p>
        <div class="d-flex justify-center pa-2">
          <v-color-picker
            v-model="event.micrositeConfiguration.secondaryColor"
            class="v-color-picker-style ma-0 ma-md-2"
            mode="hexa"
            hide-mode-switch
            show-swatches
            swatches-max-height="300px"
          ></v-color-picker>
        </div>
        <v-card-actions class="d-flex justify-end pb-5 pr-5">
          <v-btn
            class="font-weight-bold black--text"
            color="grey lighten-3"
            rounded
            elevation="0"
            @click="cancelUpdateEvent(), (secondaryColorDialog = false)"
            >Cancelar</v-btn
          >
          <v-btn
            class="font-weight-bold black--text"
            color="accent"
            rounded
            elevation="0"
            @click="_updateEvent(), (secondaryColorDialog = false)"
            >Guardar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--FIN v-dialog para seleccionar el color secundario-->
    <!--INICIO v-dialog para seleccionar el color del botón-->
    <v-dialog v-model="buttonColorDialog" max-width="400">
      <v-card>
        <p class="title font-weight-bold text-center px-7 pt-5">
          Selecciona el color del botón
        </p>
        <div class="d-flex justify-center pa-2">
          <v-color-picker
            v-model="event.micrositeConfiguration.buttonColor"
            class="v-color-picker-style ma-0 ma-md-2"
            mode="hexa"
            hide-mode-switch
            show-swatches
            swatches-max-height="300px"
          ></v-color-picker>
        </div>
        <v-card-actions class="d-flex justify-end pb-5 pr-5">
          <v-btn
            class="font-weight-bold black--text"
            color="grey lighten-3"
            rounded
            elevation="0"
            @click="cancelUpdateEvent(), (buttonColorDialog = false)"
            >Cancelar</v-btn
          >
          <v-btn
            class="font-weight-bold black--text"
            color="accent"
            rounded
            elevation="0"
            @click="_updateEvent(), (buttonColorDialog = false)"
            >Guardar</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--FIN v-dialog para seleccionar el color del botón-->
    <!--INICIO v-dialog para cargar patrocinador-->
    <v-dialog v-model="newEventMicrositeDialog" width="700">
      <v-card>
        <v-card-text :class="$vuetify.breakpoint.smAndUp ? '' : 'px-2'">
          <v-container>
            <v-row>
              <v-col cols="12" class="mt-3 d-flex justify-center">
                <v-img
                  class=""
                  src="@/assets/images/weChamber/logo-WC-main-nav-drawer.svg"
                  max-width="190"
                  height="auto"
                  contain
                ></v-img>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <p class="headline font-weight-bold text-center mb-0">
                  Configuración del micrositio
                </p>
              </v-col>
              <v-col cols="12" class="subtitle-1 text-justify py-0">
                <p>
                  En este apartado podrás configurar todos los datos relevantes
                  sobre tu evento. Por ejemplo, el nombre del evento, colores
                  principales del micrositio, redes sociales, patrocinadores de
                  tu evento.
                </p>
              </v-col>
            </v-row>
            <v-row
              class="d-flex pb-2 mt-5"
              :class="
                $vuetify.breakpoint.smAndUp ? 'justify-end' : 'justify-center'
              "
            >
              <v-btn
                class="mx-2"
                color="grey lighten-2"
                rounded
                @click="newEventMicrositeDialog = false"
                elevation="0"
                ><span class="font-weight-bold black--text"
                  >Entendido</span
                ></v-btn
              >
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!--FIN v-dialog para cargar patrocinador-->
  </v-container>
</template>
<script>
import axios from "axios";

import qs from "query-string";
import { Base64 } from "js-base64";
import Endpoints from "@/share/Endpoints";
import {
  TiptapVuetify,
  Heading,
  Bold,
  Italic,
  Strike,
  Underline,
  Code,
  Paragraph,
  BulletList,
  OrderedList,
  ListItem,
  Link,
  Blockquote,
  HardBreak,
  HorizontalRule,
  History,
} from "tiptap-vuetify";
import { imageHelper } from "@/utils/imageProcessing.js";
import { mapState, mapMutations, mapActions } from "vuex";

// Plugin styles
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";
// Filepond plugins
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";

//VueFilePond
import vueFilePond from "vue-filepond";
import "filepond/dist/filepond.min.css";
const FilePond = vueFilePond(FilePondPluginFileValidateType);

import ImageCroppingDialog from "../../../components/shared/ImageCroppingDialog.vue";

export default {
  components: {
    TiptapVuetify,
    FilePond,
    ImageCroppingDialog,
  },
  data() {
    return {
      company: "",
      emails: [],
      selectedSubgroups: [],
      confirmationDialog: false,
      selectedOption: "no",
      subgroups: ["Enviar a Todos"],

      eventModalities: {
        HYBRID: "Híbrido",
        PRESENTIAL: "Presencial",
        VIRTUAL: "Virtual",
      },
      files: null,
      newEventMicrositeDialog: false,
      bannerDialog: false,
      flyerDialog: false,
      sponsorDialog: false,
      sponsorLink: "",
      removeSponsorDialog: false,
      descriptionDialog: false,
      primaryColorDialog: false,
      secondaryColorDialog: false,
      buttonColorDialog: false,
      place: null,
      description: "",
      removeSponsorSelected: {},
      event: {
        _id: "",
        published: false,
        banner: "",
        name: "",
        description: "",
        youtubeVideo: "",
        emailContact: "",
        micrositeConfiguration: {
          link: "",
          primaryColor: "",
          secondaryColor: "",
          buttonColor: "",
          textButtonColor: "white",
        },
        presentialEventConfiguration: {
          name: "",
          city: "",
          country: "",
          formattedAddress: "",
          latitude: null,
          longitude: null,
        },
        socialMedia: {
          facebook: "",
          instagram: "",
          linkedin: "",
          twitter: "",
          youtubeChanel: "",
        },
      },
      // Copia del objeto del evento. Se usa para guardar los datos del evento de forma temporal, y sirve para regresar los valores de un evento en caso de que el usuario quiera cancelar alguna operación.
      eventTemp: {
        _id: "",
        published: false,
        banner: "",
        name: "",
        description: "",
        youtubeVideo: "",
        emailContact: "",
        micrositeConfiguration: {
          link: "",
          primaryColor: "",
          secondaryColor: "",
          buttonColor: "",
          textButtonColor: "white",
        },
        presentialEventConfiguration: {
          name: "",
          city: "",
          country: "",
          formattedAddress: "",
          latitude: null,
          longitude: null,
        },
        virtualEventConfiguration: {
          url: "",
          meetingId: "",
          meetingPassword: "",
        },
      },
      // Diferentes modalidades del evento
      modalityCategories: [
        {
          item: "Presencial",
          value: "PRESENTIAL",
        },
        {
          item: "En línea",
          value: "VIRTUAL",
        },
        {
          item: "Híbrido",
          value: "HYBRID",
        },
      ],
      extensions: [
        History,
        Blockquote,
        Link,
        Underline,
        Strike,
        Italic,
        ListItem,
        BulletList,
        OrderedList,
        [
          Heading,
          {
            options: {
              levels: [1, 2, 3],
            },
          },
        ],
        Bold,
        Code,
        HorizontalRule,
        Paragraph,
        HardBreak,
      ],
    };
  },
  computed: {
    ...mapState("event", ["eventSelected"]),
    ...mapState("user", ["user"]),
    isFlyerEventTitle() {
      return this.dialogTitle === "Flyer de evento";
    },
  },
  methods: {
    ...mapActions("user", ["realoadUserData"]),
    ...mapActions("event", [
      "fetchEventById",
      "updateEvent",
      "addEventSponsor",
      "removeEventSponsor",
    ]),
    ...mapMutations("loader", ["loading", "loaded"]),
    ...mapMutations("notification", ["show"]),
    async openNotificationDialog(){
      this.confirmationDialog = true;

    },
    async confirmPublish() {
      try {
        this.loading();

        if (this.selectedOption === "yes") {
          const emails = await this.fetchEmails();
          //console.log("Correos a enviar:", emails);
          await this.axios.post("users/send-event-notification", {
            eventTitle: this.event.name,
            emails: emails,
          });

          this.show({
            color: "success",
            text: "Notificaciones enviadas con éxito.",
          });
        }

        await this.$_publishEvent();
        this.event.published = true;
        this.confirmationDialog = false;
      } catch (error) {
        this.show({
          color: "error",
          text: error.response?.data || "Error al enviar la notificación.",
        });
      } finally {
        this.loaded();
      }
    },
    onOptionChange() {
      if (this.selectedOption === "yes") {
        this.confirmDialog2 = true;
      } else {
        this.selectedSubgroups = [];
      }
    },
    async fetchSubgroups() {
      try {
        const response = await axios.get("/subgroups/");
        this.subgroups = [
          "Enviar a Todos",
          ...response.data.map((subgroup) => subgroup.name),
        ];
      } catch (error) {
        console.error("Error fetching subgroups:", error);
      }
    },
    async fetchEmails() {
      try {
        const response = await axios.get("/subgroups/");

        //console.log("Selected Subgroups:", this.selectedSubgroups);

        if (this.selectedSubgroups.includes("Enviar a Todos")) {
          //console.log("Response Data:", response.data);

          this.emails = response.data.flatMap((subgroup) => {
            //console.log("Subgroup:", subgroup); 
            return Array.isArray(subgroup.members)
              ? subgroup.members.map((member) => {
                  //console.log("Member Email:", member.email); 
                  return member.email;
                })
              : [];
          });
        } else {
          this.emails = response.data
            .filter((subgroup) => {
              //console.log("Subgroup Name:", subgroup.name);
              return this.selectedSubgroups.includes(subgroup.name);
            })
            .flatMap((subgroup) =>
              Array.isArray(subgroup.members)
                ? subgroup.members.map((member) => member.email)
                : []
            );
        }

        //console.log("Emails:", this.emails);
        return this.emails;
      } catch (error) {
        console.error("Error fetching emails:", error);
        this.show({
          color: "error",
          text: "Error al obtener los correos electrónicos.",
        });
        return [];
      }
    },

    handleBanderaImageChange(value) {
      this.banderaImage = value;
    },

    handleMultipleImagesUpdate(isMultiple) {
      // 'isMultiple' es el valor que recibes desde el componente hijo
      // Aquí puedes actualizar tu lógica o estado basado en este valor
      console.log(
        "Multiple images option is " + (isMultiple ? "enabled" : "disabled")
      );
      // Por ejemplo, actualiza una variable de datos
      this.isMultipleImagesEnabled = isMultiple;
    },

    async fetchEventInformation() {
      let auxEvent = JSON.parse(JSON.stringify(this.event));
      await this.fetchEventById(this.$route.params.eventId);

      // Convertir la descripción de base64 a texto normal
      this.eventSelected.description = Base64.decode(
        this.eventSelected.description
      );
      if (
        this.eventSelected.presentialEventConfiguration &&
        this.eventSelected.presentialEventConfiguration.formattedAddress
      ) {
        this.place =
          this.eventSelected.presentialEventConfiguration.formattedAddress;
      }
      Object.assign(auxEvent, this.eventSelected);
      this.event = JSON.parse(JSON.stringify(auxEvent));
      this.eventTemp = JSON.parse(JSON.stringify(this.eventSelected));
    },
    cancelUpdateEvent() {
      this.event = JSON.parse(JSON.stringify(this.eventTemp));
    },
    async $_publishEvent() {
      try {
        this.loading();
        const endpoint = qs.stringifyUrl({
          url: Endpoints.publishEvent.replace(
            /:eventId/,
            this.$route.params.eventId
          ),
        });
        const response = await this.axios.put(endpoint);
        if (response.status > 199 && response.status < 300) {
          await this.fetchEventInformation();
          this.loaded();
          this.show({
            text: "¡Evento publicado correctamente!",
            color: "primary",
          });
        } else {
          this.show({
            text: "¡Ha ocurrido un problema en la publicación del evento!",
            color: "error",
          });
        }
        this.loaded();
      } catch (error) {
        setTimeout(() => {
          this.show({
            text:
              error.response &&
              error.response.data &&
              error.response.data.error &&
              error.response.data.error.message
                ? error.response.data.error.message
                : "¡Ha ocurrido un problema al querer publicar el evento!",
            color: "error",
          });
          this.loaded();
        }, 1000);
      }
    },
    async $_hideEvent() {
      try {
        this.loading();
        const endpoint = qs.stringifyUrl({
          url: Endpoints.hideEvent.replace(
            /:eventId/,
            this.$route.params.eventId
          ),
        });
        const response = await this.axios.put(endpoint);
        if (response.status > 199 && response.status < 300) {
          await this.fetchEventInformation();
          this.show({
            text: "¡Evento ocultado correctamente!",
            color: "primary",
          });
          this.loaded();
        } else {
          this.show({
            text: "¡Ha ocurrido un problema al ocultar el evento!",
            color: "error",
          });
        }
        this.loaded();
      } catch (error) {
        setTimeout(() => {
          this.show({
            text: error.message
              ? error.message
              : "¡Ha ocurrido un problema al querer ocultar el evento!",
            color: "error",
          });
          this.loaded();
        }, 1000);
      }
    },
    async _updateEvent() {
      try {
        this.loading();
        this.eventTemp = JSON.parse(JSON.stringify(this.event));
        this.eventTemp.description = Base64.encode(this.eventTemp.description);
        let response = await this.updateEvent(this.eventTemp);
        this.eventTemp = JSON.parse(JSON.stringify(this.event));
        // Cuando este en producción quitar el setTimeout
        this.loaded();
      } catch (error) {
        this.loaded();
        console.log(error.message);
      }
    },
    async uploadBanners(bannersArray) {
      this.loading();
      for (const banner of bannersArray) {
        await this.uploadBanner(banner);
      }
      this.loaded();
    },
    async uploadBanner(file) {
      this.loading();
      let bannerTemp = await imageHelper.resizeImg(file, 1500, 500);
      this.event.banner = await imageHelper.saveImage(bannerTemp);
      console.log(this.event.banner);
      this.eventTemp = JSON.parse(JSON.stringify(this.event));
      this.eventTemp.description = Base64.encode(this.eventTemp.description);
      await this.updateEvent(this.eventTemp);
      this.eventTemp = JSON.parse(JSON.stringify(this.event));
      this.bannerDialog = false;
      // Cuando este en producción quitar el setTimeout
      this.loaded();
    },
    async uploadFlyers(flyersArray) {
      this.loading();
      for (const flyer of flyersArray) {
        await this.uploadFlyer(flyer);
      }
      this.loaded();
    },
    async uploadFlyer(file, indexToUpdate) {
      if (!this.isMultipleImagesEnabled) {
        this.event.flyer = "";
      }
      this.banderaImage == true;
      let flyerTemp = await imageHelper.resizeImg(file, 800, 2000);
      const flyerLink = await imageHelper.saveImage(flyerTemp);

      console.log("Enlace del flyer:", this.event.flyer);

      // Divide los enlaces existentes por comas
      let existingFlyers = this.event.flyer ? this.event.flyer.split(",") : [];

      if (indexToUpdate !== undefined && existingFlyers[indexToUpdate]) {
        // Actualiza la imagen en el índice específico
        existingFlyers[indexToUpdate] = flyerLink;
      } else if (existingFlyers.length < 3) {
        // Agrega una nueva imagen si el arreglo tiene menos de 3 elementos
        existingFlyers.push(flyerLink);
      } else {
        // Reemplaza la primera imagen si el arreglo ya tiene 3 elementos
        existingFlyers[0] = flyerLink;
      }

      console.log("Enlaces existentes:", existingFlyers);

      // Une los enlaces y actualiza el estado
      this.event.flyer = existingFlyers.join(",");

      console.log("Enlace del flyer:", this.event.flyer);

      this.eventTemp = JSON.parse(JSON.stringify(this.event));
      this.eventTemp.description = Base64.encode(this.eventTemp.description);

      await this.updateEvent(this.eventTemp);
      this.eventTemp = JSON.parse(JSON.stringify(this.event));
      this.flyerDialog = false;
    },

    async uploadSponsor() {
      this.loading();
      const link = this.sponsorLink;
      const file = this.$refs.sponsorImage.getFiles()[0].file;
      let sponsorTemp = await imageHelper.resizeImg(file, 400, 400);
      let sponsorImg = await imageHelper.saveSponsorImage(sponsorTemp);

      let sponsorData = {
        eventId: this.$route.params.eventId,
        name: "",
        logo: sponsorImg,
        url: link,
      };
      await this.addEventSponsor(sponsorData);
      this.sponsorDialog = false;
      this.$refs.sponsorImage.removeFiles();
      // Cuando este en producción quitar el setTimeout
      setTimeout(() => {
        this.loaded();
      }, 500);
      await this.fetchEventInformation();
    },
    async removeSponsor() {
      this.loading();
      let sponsorData = {
        eventId: this.$route.params.eventId,
        sponsorId: this.removeSponsorSelected._id,
        logo: this.removeSponsorSelected.logo,
      };
      await this.removeEventSponsor(sponsorData);
      await this.fetchEventInformation();
      this.removeSponsorDialog = false;
      setTimeout(() => {
        this.loaded();
      }, 500);
    },
    /**
     * Función para borrar un patrocinador de la lista de patrocinadores
     */
    openRemoveSponsorDialog(sponsor) {
      this.removeSponsorSelected = sponsor;
      this.removeSponsorDialog = true;
    },
    /**
     * Función para guardar dirección del evento una vez que ha sido ingresada en el campo de dirección de evento.
     */
    async saveAddress(place) {
      let {
        geometry: {
          location: { lat },
        },
        geometry: {
          location: { lng },
        },
        formatted_address,
        name,
      } = place;
      let flag = isNaN(place.address_components.slice(-1)[0].short_name)
        ? true
        : false;
      let components = [];
      if (flag) components = [...place.address_components].slice(-3);
      else components = [...place.address_components].slice(-4);

      this.event.presentialEventConfiguration = {
        name,
        city: components[0].long_name,
        country: components[2].long_name,
        formattedAddress: formatted_address,
        latitude: lat(),
        longitude: lng(),
        //state: components[1].short_name,
      };
      this.place = formatted_address;
      await this._updateEvent();
    },
    dateEvent() {
      if (
        this.event.dateConfiguration &&
        this.event.dateConfiguration.startDate != ""
      ) {
        let startDate =
          this.dateFormatted(
            this.event.dateConfiguration.startDate.substring(0, 16)
          ) +
          "-" +
          this.event.dateConfiguration.startDate.substring(0, 4);
        let endDate =
          this.dateFormatted(
            this.event.dateConfiguration.endDate.substring(0, 16)
          ) +
          "-" +
          this.event.dateConfiguration.endDate.substring(0, 4);
        if (startDate == endDate) {
          return startDate;
        } else {
          return `${startDate} / ${endDate}`;
        }
      } else {
        return "";
      }
    },
    dateFormatted(eventDate) {
      const date = new Date(eventDate);
      const month = date.toLocaleString("es-mx", { month: "long" });
      const day = date.getDate();
      return `${("00" + day).slice(-2)}-${month}`;
    },
  },
  async mounted() {
    this.realoadUserData({ id: this.company });
    //console.log(this.company);
    await this.fetchSubgroups();
    await this.fetchEventInformation();
    this.eventTemp = JSON.parse(JSON.stringify(this.event));
  },
};
</script>
<style scoped>
.bg-color {
  background-color: aquamarine;
}

.sponsor-img {
  filter: grayscale(1);
  height: auto;
  /*max-height: 100px;
  width: 100%;
  max-width: 150px;*/
  transition: 0.2s all ease-in-out;
  border-radius: 15px;
  justify-self: center;
}

.v-color-picker-style {
  background-color: #ffffff00;
}
</style>